<template>
  <div>
    <NuxtLoadingIndicator />
    <NuxtLayout>
      <NuxtPage/>
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
useHead({
  // or as a function
  titleTemplate: (title) => {
    return title
      ? `Beegup - ${title}`
      : 'Beegup'
  }
})
</script>
