<template>
  <div>
    <div v-if="isBackdrop" class="backdrop" @click="hideBackdrop" />

    <header :class="{ 'header-backdrop': isBackdrop }" role="banner">
      <nav role="navigation"
        class="relative w-full mx-auto max-w-[80rem] px-4 sm:px-6 lg:px-8 hidden md:flex"
      >
        <div class="flex items-center justify-between w-full space-x-8">
          <div class="flex items-center gap-2">
            <img
              v-if="!burgerMenuVisible"
              src="/images/menu.svg"
              :alt="$t('LB_MENU')"
              class="w-[28px] h-[28px] object-contain cursor-pointer"
              @click="toggleMenu"
            >
            <img
              v-else
              src="/images/close.svg"
              :alt="$t('LB_MENU')"
              class="w-[28px] h-[28px] object-contain cursor-pointer"
              @click="toggleMenu"
            >
            <div
              v-if="burgerMenuVisible"
              class="p-6 orange-gradient absolute shadow-lg top-16 left-0 mx-4 my-2 min-w-[213px] z-10 rounded-lg"
            >
              <ul
                class="list-none flex justify-end flex-col items-start gap-4 pl-0"
              >
                <li class="text-white font-bold text-lg mb-5">
                  {{ $t("LB_MENU") }}
                </li>
                <li class="font-poppins text-[16px] font-medium cursor-pointer">
                  <span class="text-white" @click="gotoHome()">
                    {{ $t("LB_HOME") }}
                  </span>
                </li>
                <li
                  v-if="me.role !== 'GRADUATE'"
                  class="font-poppins text-[16px] font-medium cursor-pointer"
                >
                  <span class="text-white" @click="gotoWorkspace">
                    {{ $t("LB_WORKSPACE") }}
                  </span>
                </li>
                <li v-if="me.mode !== 'GUIDED'" class="font-poppins text-[16px] font-medium cursor-pointer">
                  <span class="text-white" @click="gotoMyCommunity()">
                    {{ $t("LB_COMMUNITY") }}
                  </span>
                </li>
                <li
                  v-if="me.role !== 'GRADUATE' && isTestActive"
                  class="font-poppins text-[16px] font-medium cursor-pointer"
                >
                  <span class="text-white" @click="gotoTests()">
                    {{ $t("LB_TESTS") }}
                  </span>
                </li>
              </ul>
            </div>
            <NuxtLinkLocale
              to="/"
              class="ml-5"
            >
              <img alt="Beegup" src="/images/beegup.png" class="header_logo">
            </NuxtLinkLocale>
          </div>

          <div class="flex items-center gap-16 flex-grow-1">
            <div v-if="me.mode !== 'GUIDED'" class="flex-grow">
              <div class="relative" role="search">
                <div
                  class="absolute inset-y-0 left-0 flex items-center pl-3 cursor-pointer"
                  @click.prevent="doSearch"
                >
                  <svg
                    class="w-4 h-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                    role="img"
                    :aria-label="$t('LB_SEARCH')"
                    @click.prevent="doSearch"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  id="default-search"
                  v-model="keyword"
                  type="search"
                  class="block w-full p-2.5 pl-10 text-sm text-gray-900 border border-gray-300 rounded-full bg-gray-50 outline-none"
                  required
                  @keydown.enter="doSearch"
                >
              </div>
            </div>
          </div>
          <div class="flex items-center gap-16">
            <div class="flex items-center justify-center gap-4">
              <div class="notification">
                <button
                  type="button"
                  class="relative inline-flex items-center p-2 text-sm font-medium text-center text-white bg-beegup-almost-white rounded-full hover:bg-white focus:outline-none focus:ring-blue-300"
                  @click="toggleNotificationPanel"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-6 h-6"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="#000"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    role="img"
                    :aria-label="$t('LB_NOTIFICATIONS')"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path
                      d="M14.235 19c.865 0 1.322 1.024 .745 1.668a3.992 3.992 0 0 1 -2.98 1.332a3.992 3.992 0 0 1 -2.98 -1.332c-.552 -.616 -.158 -1.579 .634 -1.661l.11 -.006h4.471z"
                      stroke-width="0"
                      fill="#000"
                    />
                    <path
                      d="M12 2c1.358 0 2.506 .903 2.875 2.141l.046 .171l.008 .043a8.013 8.013 0 0 1 4.024 6.069l.028 .287l.019 .289v2.931l.021 .136a3 3 0 0 0 1.143 1.847l.167 .117l.162 .099c.86 .487 .56 1.766 -.377 1.864l-.116 .006h-16c-1.028 0 -1.387 -1.364 -.493 -1.87a3 3 0 0 0 1.472 -2.063l.021 -.143l.001 -2.97a8 8 0 0 1 3.821 -6.454l.248 -.146l.01 -.043a3.003 3.003 0 0 1 2.562 -2.29l.182 -.017l.176 -.004z"
                      stroke-width="0"
                      fill="#000"
                    />
                  </svg>
                  <div
                    v-show="notifications.length > 0"
                    class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -right-2"
                  >
                    {{ notifications.length }}
                  </div>
                </button>
                <div
                  v-if="showNotificationPanel"
                  class="flex p-6 bg-white shadow-xl text-black absolute md:top-16 top-28 divide-y right-16 mx-4 my-2 w-[20rem] z-10 rounded-lg"
                >
                  <ul
                    class="list-none flex justify-end flex-col items-start gap-4 pl-0"
                  >
                    <li
                      class="text-black font-extrabold text-lg mb-3 tracking-wider"
                    >
                      {{ $t("LB_NOTIFICATIONS") }}
                    </li>
                    <div class="divide-y divide-gray-200">
                      <li
                        v-if="notifications.length === 0"
                        class="text-black font-normal text-xs mb-3 tracking-wider"
                      >
                        {{ $t("LB_NO_NOTIFICATION") }}
                      </li>
                      <li
                        v-for="notification in notifications"
                        :key="notification.id"
                        class="flex items-center justify-start space-x-4 font-poppins py-2 text-xs cursor-pointer hover:bg-gray-100 text-secondary"
                        @click="handleNotificationClick(notification)"
                      >
                        <div class="relative shrink-0">
                          <img
                            v-show="notification.referencedUser"
                            class="rounded-full object-cover w-10 h-10"
                            :src="getUserImage(notification.referencedUser)"
                            alt=""
                          >
                          <div
                            class="absolute inline-flex items-center justify-center w-6 h-6 text-[10px] font-bold text-white bg-beegup-blue border-2 border-white rounded-full -top-2 -right-2"
                          >
                            {{ notification.count }}
                          </div>
                        </div>

                        <div class="space-y-1">
                          <p class="text-xs mb-0 tracking-tight">
                            {{ notification.content }}
                          </p>
                        </div>
                      </li>
                    </div>
                  </ul>
                </div>
              </div>

              <div class="message">
                <button
                  type="button"
                  class="relative inline-flex items-center p-2 text-sm font-medium text-center text-white bg-beegup-almost-white rounded-full hover:bg-white focus:outline-none focus:ring-blue-300"
                  @click="toggleMessagePanel"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="w-6 h-6"
                    role="img"
                    :aria-label="$t('LB_MESSAGES')"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.804 21.644A6.707 6.707 0 006 21.75a6.721 6.721 0 003.583-1.029c.774.182 1.584.279 2.417.279 5.322 0 9.75-3.97 9.75-9 0-5.03-4.428-9-9.75-9s-9.75 3.97-9.75 9c0 2.409 1.025 4.587 2.674 6.192.232.226.277.428.254.543a3.73 3.73 0 01-.814 1.686.75.75 0 00.44 1.223zM8.25 10.875a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25zM10.875 12a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875-1.125a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25z"
                      clip-rule="evenodd"
                    />
                  </svg>

                  <span class="sr-only">{{ $t("LB_MESSAGES") }}</span>
                  <div
                    v-show="unreadConversations?.length > 0"
                    class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -right-2"
                  >
                    {{ unreadConversations.length }}
                  </div>
                </button>
                <div
                  :class="{ hidden: !showMessagePanel }"
                  class="flex p-6 bg-white shadow-md text-black absolute md:top-16 top-28 divide-y right-0 mx-4 my-2 w-[20rem] z-10 rounded-lg"
                  style="left: 65%"
                >
                  <ul
                    class="list-none flex justify-end flex-col items-start gap-4 pl-0" style="width:100%;"
                  >
                    <li
                      class="text-black font-extrabold text-lg mb-3 tracking-wider"
                    >
                      {{ $t("LB_MESSAGES") }}
                    </li>
                    <div class="divide-y divide-gray-200 w-full">
                      <li
                        v-if="unreadConversations?.length === 0"
                        class="text-black font-normal text-xs mb-3 tracking-wider"
                      >
                        {{ $t("LB_NO_MESSAGE_YET") }}
                      </li>
                      <template v-if="unreadConversations?.length > 0">
                        <li
                          v-for="conversation in unreadConversations"
                          :key="'single_message_' + conversation.id"
                          class="py-2 text-xs cursor-pointer hover:bg-gray-100 text-secondary"
                          style="width: 100%"
                          @click="gotoConversation(conversation)"
                        >
                          <div class="">
                            <div class="flex items-center gap-3">
                              <div class="relative shrink-0">
                                <img
                                  class="rounded-full object-cover w-10 h-10"
                                  :src="getUserImage(conversation.user)"
                                  alt=""
                                >
                                <div
                                  class="absolute inline-flex items-center justify-center w-6 h-6 text-[10px] font-bold text-white bg-beegup-blue border-2 border-white rounded-full -top-2 -right-2"
                                >
                                  {{ conversation.unreadMessagesCount }}
                                </div>
                              </div>
                              <div class="flex flex-col" style="width:100%;">
                                <div class="flex items-start justify-between">
                                  <div>{{ conversation.user.fullName }}<br role="presentation"></div>
                                  <small class="text-[10px] mt-1 text-gray-600">
                                    {{ formatTime(conversation.lastMessage.createdAt) }}
                                  </small>
                                </div>

                                <div v-if="conversation.lastMessage.type === 'TEXT'">
                                  <p class="text-message text-xs text-gray-600">
                                    {{ truncateText(JSON.parse(conversation.lastMessage.content), 45) }}
                                  </p>
                                </div>
                                <div v-if="conversation.lastMessage.type === 'AUDIO'">
                                  <div class="text-message text-xs text-gray-600">
                                    Audio
                                  </div>
                                </div>
                                <div v-if="conversation.lastMessage.type === 'CONTENT'">
                                  <p>
                                    Content
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </template>
                      <img
                        class="w-5 h-5 rounded-full"
                        src="/images/message_white.svg"
                        alt=""
                      >
                    </div>

                    <button
                      class="text-beegup-blue text-center font-bold text-xs mb-0 w-full flex justify-center tracking-wider"
                      @click.prevent="gotoChats"
                    >
                      {{ $t("LB_SEE_ALL_MESSAGES") }}
                    </button>
                  </ul>
                </div>
              </div>
            </div>

            <div class="flex items-center justify-center gap-4">
              <div class="profile">
                <button class="flex" type="button" @click="toggleProfile">
                  <img
                    class="h-8 w-8 rounded-full"
                    :src="getUserImage(me)"
                    :alt="$t('LB_MENU')"
                    width="40"
                    height="40"
                  >
                </button>
                <div
                  v-if="isProfile"
                  class="flex p-8 bg-white shadow-md text-black absolute md:top-14 top-28 divide-y right-0 mx-4 my-2 w-[18rem] z-10 rounded-lg"
                >
                  <ul
                    class="list-none flex flex-col items-start gap-4 w-full !pl-0"
                  >
                    <div class="w-full space-y-2">
                      <div>
                        <li
                          class="hover:bg-gray-100 text-secondary"
                          @click="handleProfileClick()"
                        >
                          <p>
                            <NuxtLinkLocale
                              to="/my-profile"
                              class="flex items-center space-x-4 font-poppins py-2 text-xs font-semibold cursor-pointer"
                            >
                              <img src="/images/profile/person.svg" alt="" >
                              <p class="text-xs mb-0">
                                {{ $t("LB_MY_PROFILE") }}
                              </p>
                            </NuxtLinkLocale>
                          </p>
                        </li>
                        <li
                          class="hover:bg-gray-100 text-secondary"
                        >
                          <p>
                              <span
                                class="flex items-center space-x-4 font-poppins py-2 text-xs font-semibold cursor-pointer"
                                @click.prevent="tryToLogout"
                              >
                                <img src="/images/profile/logout.svg" alt="" >
                                <p class="text-xs mb-0">
                                  {{ $t("LB_LOGOUT") }}
                                </p>
                              </span>
                          </p>
                        </li>
                      </div>
                      <hr
                        class="my-4 h-[1px] border-t-0 bg-beegup-light-grey opacity-100"
                      >
                      <div class="mt-2">
                        <li
                          class="hover:bg-gray-100 text-secondary"
                          @click="handleProfileClick()"
                        >
                          <p>
                            <NuxtLinkLocale
                              to="/faqs"
                              target="_blank"
                              class="flex items-center space-x-4 font-poppins py-2 text-xs font-semibold cursor-pointer"
                            >
                              <img src="/images/profile/faq.svg" alt="" >
                              <p class="text-xs mb-0">{{ $t("LB_FAQS") }}</p>
                            </NuxtLinkLocale>
                          </p>
                        </li>
                        <li
                          v-if="me.role !== 'GRADUATE'"
                          class="hover:bg-gray-100 text-secondary"
                          @click="handleProfileClick()"
                        >
                          <p>
                            <a
                              :href="
                                  '/docs/' +
                                  $i18n.locale +
                                  '/parental-permission.pdf'
                                "
                              target="_blank"
                              class="flex items-center space-x-4 font-poppins py-2 text-xs font-semibold cursor-pointer"
                            >
                              <img src="/images/profile/parental.svg" alt="" >
                              <p class="text-xs mb-0">
                                {{ $t("LB_PARENTAL_PERMISSION") }}
                              </p>
                            </a>
                          </p>
                        </li>
                        <li
                          class="hover:bg-gray-100 text-secondary"
                          @click="handleProfileClick()"
                        >
                          <p>
                            <a
                              :href="
                                  '/docs/' +
                                  $i18n.locale +
                                  '/terms-and-conditions.pdf'
                                "
                              target="_blank"
                              class="flex items-center space-x-4 font-poppins py-2 text-xs font-semibold cursor-pointer"
                            >
                              <img src="/images/profile/cgu.svg" alt="" >
                              <p class="text-xs mb-0">
                                {{ $t("LB_TERMS_AND_CONDITIONS") }}
                              </p>
                            </a>
                          </p>
                        </li>
                        <li
                          class="hover:bg-gray-100 text-secondary"
                          @click="handleProfileClick()"
                        >
                          <p>
                            <a
                              :href="
                                  '/docs/' + $i18n.locale + '/legal-notice.pdf'
                                "
                              target="_blank"
                              class="flex items-center space-x-4 font-poppins py-2 text-xs font-semibold cursor-pointer"
                            >
                              <img src="/images/profile/cgu.svg" alt="" >
                              <p class="text-xs mb-0">
                                {{ $t("LB_LEGAL_NOTICE") }}
                              </p>
                            </a>
                          </p>
                        </li>
                        <li
                          class="hover:bg-gray-100 text-secondary"
                          @click="handleProfileClick()"
                        >
                          <p>
                            <a
                              :href="
                                  '/docs/' +
                                  $i18n.locale +
                                  '/privacy-policy.pdf'
                                "
                              target="_blank"
                              class="flex items-center space-x-4 font-poppins py-2 text-xs font-semibold cursor-pointer"
                            >
                              <img src="/images/profile/cgu.svg" alt="" >
                              <p class="text-xs mb-0">
                                {{ $t("LB_PRIVACY_POLICY") }}
                              </p>
                            </a>
                          </p>
                        </li>
                        <li
                          class="hover:bg-gray-100 text-secondary"
                          @click="handleProfileClick()"
                        >
                          <p>
                            <a
                              :href="
                                  '/docs/' + $i18n.locale + '/cookie-policy.pdf'
                                "
                              target="_blank"
                              class="flex items-center space-x-4 font-poppins py-2 text-xs font-semibold cursor-pointer"
                            >
                              <img src="/images/profile/cgu.svg" alt="" >
                              <p class="text-xs mb-0">
                                {{ $t("LB_COOKIES_POLICY") }}
                              </p>
                            </a>
                          </p>
                        </li>
                        <li
                          class="hover:bg-gray-100 text-secondary"
                          @click="handleProfileClick()"
                        >
                          <p>
                            <a
                              :href="
                                  '/docs/' + $i18n.locale + '/charter.pdf'
                                "
                              target="_blank"
                              class="flex items-center space-x-4 font-poppins py-2 text-xs font-semibold cursor-pointer"
                            >
                              <img src="/images/profile/cgu.svg" alt="" >
                              <p class="text-xs mb-0">
                                {{ $t("LB_CHARTER") }}
                              </p>
                            </a>
                          </p>
                        </li>
                        <li
                          class="hover:bg-gray-100 text-secondary"
                          @click="handleProfileClick()"
                        >
                          <p>
                            <NuxtLinkLocale
                              to="/cookies-management"
                              class="flex items-center space-x-4 font-poppins py-2 text-xs font-semibold cursor-pointer"
                            >
                              <img src="/images/profile/cgu.svg" alt="" >
                              <p class="text-xs mb-0">
                                {{ $t("LB_COOKIES_MANAGEMENT") }}
                              </p>
                            </NuxtLinkLocale>
                          </p>
                        </li>
                      </div>
                      <hr
                        class="my-4 h-[1px] border-t-0 bg-beegup-light-grey opacity-100"
                      >
                      <div class="mt-2">
                        <li
                          v-if="me.role !== 'GRADUATE'"
                          class="hover:bg-gray-100 text-secondary"
                          @click="handleProfileClick()"
                        >
                          <p>
                            <a
                              :href="
                                  '/docs/' +
                                  $i18n.locale +
                                  '/tutorial-' +
                                  me.role?.toLowerCase() +
                                  '.pdf'
                                "
                              target="_blank"
                              class="flex items-center space-x-4 font-poppins py-2 text-xs font-semibold cursor-pointer"
                            >
                              <img src="/images/profile/aide.svg" alt="" >
                              <p class="text-xs mb-0">
                                {{ $t("LB_TUTORIALS") }}
                              </p>
                            </a>
                          </p>
                        </li>
                        <li
                          class="hover:bg-gray-100 text-secondary"
                          @click="handleProfileClick()"
                        >
                          <p>
                            <NuxtLinkLocale
                              to="/contact"
                              class="flex items-center space-x-4 font-poppins py-2 text-xs font-semibold cursor-pointer"
                            >
                              <img src="/images/profile/contact.svg" alt="" >
                              <p class="text-xs mb-0">
                                {{ $t("LB_CONTACT") }}
                              </p>
                            </NuxtLinkLocale>
                          </p>
                        </li>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
              <div class="languages !pb-0">
                <button
                  type="button"
                  class="flex items-center space-x-2"
                  @click="toggleLanguageMenu"
                >
                  <img
                    class="h-8 w-8 rounded-full"
                    :src="getFlagPath($i18n.locale)"
                    :alt="$t($i18n.locale)"
                    width="40"
                    height="40"
                  >
                  <img src="/images/arrow-down.svg" alt="" >
                </button>

                <div
                  v-if="languageMenuVisible"
                  class="fle bg-white shadow-lg text-black absolute md:top-14 top-28 divide-y right-0 mx-4 my-2 w-[14rem] z-10 rounded-lg"
                >
                  <ul
                    class="list-none flex flex-col items-start gap-4 w-full !pl-0"
                  >
                    <div class="w-full space-y-2">
                      <div class="divide-y">
                        <li
                          class="text-black font-extrabold text-lg p-6 tracking-wider"
                        >
                          {{ $t("LB_LANGUAGES") }}
                        </li>
                        <template v-for="availableLocale in availableLocales" :key="availableLocale.id">
                          <li
                            class="flex items-center space-x-4 font-poppins p-6 text-xs font-semibold cursor-pointer"
                            @click="chooseLanguage(availableLocale)">
                            <img
                              :alt="$t('availableLocale.code')"
                              :src="getFlagPath(availableLocale.code)"
                              class="w-5 h-5 rounded-full object-cover"
                            >
                            <p class="text-xs mb-0">{{ availableLocale.name }}</p>
                          </li>
                        </template>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <!-- mobile -->
      <nav role="navigation"
        class="relative w-full mx-auto px-4 sm:px-6 lg:px-8 flex flex-col gap-y-8 md:hidden"
      >
        <div class="flex items-center justify-between">
          <div class="flex items-center gap-2">
            <img
              v-if="!burgerMenuVisible"
              src="/images/menu.svg"
              :alt="$t('LB_MENU')"
              class="w-full h-full object-contain cursor-pointer"
              @click="toggleMenu"
            >
            <img
              v-else
              src="/images/close.svg"
              :alt="$t('LB_MENU')"
              class="w-full h-full object-contain cursor-pointer"
              @click="toggleMenu"
            >
            <div
              v-if="burgerMenuVisible"
              class="p-6 orange-gradient absolute top-16 left-0 mx-4 my-2 min-w-[213px] z-10 rounded-xl"
            >
              <ul
                class="list-none flex justify-end flex-col items-start gap-4 pl-4"
              >
                <li class="text-white font-bold text-lg mb-5">
                  {{ $t("LB_MENU") }}
                </li>
                <li class="font-poppins text-[16px] font-medium cursor-pointer">
                  <span class="text-white" @click="gotoHome">{{
                    $t("LB_HOME")
                  }}</span>
                </li>
                <li v-if="me.role !== 'GRADUATE'" class="font-poppins text-[16px] font-medium cursor-pointer">
                  <span class="text-white" @click="gotoWorkspace">{{
                    $t("LB_WORKSPACE")
                  }}</span>
                </li>
                <li v-if="me.mode !== 'GUIDED'" class="font-poppins text-[16px] font-medium cursor-pointer">
                  <span class="text-white" @click="gotoMyCommunity()">{{
                    $t("LB_COMMUNITY")
                  }}</span>
                </li>
                <li
                  v-if="me.role !== 'GRADUATE' && isTestActive"
                  class="font-poppins text-[16px] font-medium cursor-pointer"
                >
                  <span class="text-white" @click="gotoTests()">{{
                    $t("LB_TESTS")
                  }}</span>
                </li>
              </ul>
            </div>
          </div>

          <NuxtLinkLocale
            to="/"
            class="ml-5 w-full flex justify-center mx-auto"
          >
            <img alt="Beegup" src="/images/beegup.png" class="header_logo">
          </NuxtLinkLocale>
          <div class="languages !pb-0">
            <button
              type="button"
              class="flex items-center space-x-2"
              @click="toggleLanguageMenu"
            >
              <img
                class="h-8 w-8 rounded-full"
                :src="getFlagPath($i18n.locale)"
                :alt="$t($i18n.locale)"
                width="40"
                height="40"
              >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="2.5"
                stroke="currentColor"
                class="w-11 h-11"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </button>

            <div
              v-if="languageMenuVisible"
              class="flex bg-white shadow-lg text-black absolute md:top-14 top-8 divide-y right-0 mx-4 my-2 w-[14rem] z-10 rounded-lg"
            >
              <ul
                class="list-none flex flex-col items-start gap-4 w-full !pl-0"
              >
                <div class="w-full space-y-2">
                  <div class="divide-y">
                    <li
                      class="text-black font-extrabold text-lg p-6 tracking-wider"
                    >
                      {{ $t("LB_LANGUAGES") }}
                    </li>
                    <template v-for="availableLocale in availableLocales" :key="availableLocale.id">
                      <li
                        class="flex items-center space-x-4 font-poppins p-6 text-xs font-semibold cursor-pointer"
                        @click="chooseLanguage(availableLocale)">
                        <img
                          :alt="$t(availableLocale.code)"
                          :src="getFlagPath(availableLocale.code)"
                          class="w-5 h-5 rounded-full object-cover"
                        >
                        <p class="text-xs mb-0">{{ availableLocale.name }}</p>
                      </li>
                    </template>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>

        <div class="flex items-center justify-between gap-2">
          <div class="relative">
            <template v-if="me.mode !== 'GUIDED'">
              <div
                class="absolute inset-y-0 left-0 flex items-center pl-3 cursor-pointer"
                @click.prevent="doSearch"
              >
                <svg
                  class="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                  role="img"
                  :aria-label="$t('LB_SEARCH')"
                  @click.prevent="doSearch"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                id="default-search-mobile"
                v-model="keyword"
                type="search"
                class="block w-full p-2 pl-10 text-xs text-gray-900 border border-gray-300 rounded-full bg-gray-50 outline-none"
                required
                @keydown.enter="doSearch"
              >
            </template>
          </div>

          <div class="flex items-center justify-end gap-2">
            <div class="notification">
              <button
                type="button"
                class="relative inline-flex items-center p-2 text-sm font-medium text-center text-white bg-beegup-almost-white rounded-full hover:bg-white focus:outline-none focus:ring-blue-300"
                @click="toggleNotificationPanel"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-6 h-6"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="#000"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  role="img"
                  :aria-label="$t('LB_NOTIFICATIONS')"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <path
                    d="M14.235 19c.865 0 1.322 1.024 .745 1.668a3.992 3.992 0 0 1 -2.98 1.332a3.992 3.992 0 0 1 -2.98 -1.332c-.552 -.616 -.158 -1.579 .634 -1.661l.11 -.006h4.471z"
                    stroke-width="0"
                    fill="#000"
                  />
                  <path
                    d="M12 2c1.358 0 2.506 .903 2.875 2.141l.046 .171l.008 .043a8.013 8.013 0 0 1 4.024 6.069l.028 .287l.019 .289v2.931l.021 .136a3 3 0 0 0 1.143 1.847l.167 .117l.162 .099c.86 .487 .56 1.766 -.377 1.864l-.116 .006h-16c-1.028 0 -1.387 -1.364 -.493 -1.87a3 3 0 0 0 1.472 -2.063l.021 -.143l.001 -2.97a8 8 0 0 1 3.821 -6.454l.248 -.146l.01 -.043a3.003 3.003 0 0 1 2.562 -2.29l.182 -.017l.176 -.004z"
                    stroke-width="0"
                    fill="#000"
                  />
                </svg>
                <div
                  v-show="notifications.length > 0"
                  class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -right-2"
                >
                  {{ notifications.length }}
                </div>
              </button>
              <div
                v-if="showNotificationPanel"
                class="flex p-6 bg-white shadow-xl text-black absolute md:top-16 top-28 divide-y right-16 mx-4 my-2 w-[20rem] z-10 rounded-lg"
              >
                <ul
                  class="list-none flex justify-end flex-col items-start gap-4 pl-0"
                >
                  <li
                    class="text-black font-extrabold text-lg mb-3 tracking-wider"
                  >
                    {{ $t("LB_NOTIFICATIONS") }}
                  </li>
                  <div class="divide-y divide-gray-200">
                    <li
                      v-if="notifications.length === 0"
                      class="text-black font-normal text-xs mb-3 tracking-wider"
                    >
                      {{ $t("LB_NO_NOTIFICATION") }}
                    </li>
                    <li
                      v-for="notification in notifications"
                      :key="notification.id"
                      class="flex items-center justify-start space-x-4 font-poppins py-2 text-xs cursor-pointer hover:bg-gray-100 text-secondary"
                      @click="handleNotificationClick(notification)"
                    >
                      <div class="relative shrink-0">
                        <img
                          v-show="notification.referencedUser"
                          class="rounded-full object-cover w-10 h-10"
                          :src="getUserImage(notification.referencedUser)"
                          alt=""
                        >
                        <div
                          class="absolute inline-flex items-center justify-center w-6 h-6 text-[10px] font-bold text-white bg-beegup-blue border-2 border-white rounded-full -top-2 -right-2"
                        >
                          {{ notification.count }}
                        </div>
                      </div>

                      <div class="space-y-1">
                        <p class="text-xs mb-0 tracking-tight">
                          {{ notification.content }}
                        </p>
                      </div>
                    </li>
                  </div>
                </ul>
              </div>
            </div>
            <div class="message">
              <button
                type="button"
                class="relative inline-flex items-center p-2 text-sm font-medium text-center text-white bg-beegup-almost-white rounded-full hover:bg-beegup-almost-white focus:outline-none focus:ring-blue-300"
                @click="toggleMessagePanel"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="w-5 h-5"
                  role="img"
                  :aria-label="$t('LB_MESSAGES')"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.804 21.644A6.707 6.707 0 006 21.75a6.721 6.721 0 003.583-1.029c.774.182 1.584.279 2.417.279 5.322 0 9.75-3.97 9.75-9 0-5.03-4.428-9-9.75-9s-9.75 3.97-9.75 9c0 2.409 1.025 4.587 2.674 6.192.232.226.277.428.254.543a3.73 3.73 0 01-.814 1.686.75.75 0 00.44 1.223zM8.25 10.875a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25zM10.875 12a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875-1.125a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span class="sr-only">{{ $t("LB_MESSAGES") }}</span>
                <div
                  v-show="unreadConversations?.length > 0"
                  class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -right-2"
                >
                  {{ unreadConversations.length }}
                </div>
              </button>
              <div
                :class="{ hidden: !showMessagePanel }"
                class="flex p-6 bg-white shadow-md text-black absolute md:top-14 top-32 divide-y right-0 mx-4 my-2 w-[18rem] z-10 rounded-lg"
              >
                <ul
                  class="list-none flex justify-end flex-col items-start gap-4 pl-0" style="width:100%;"
                >
                  <li
                    class="text-black font-extrabold text-lg mb-3 tracking-wider"
                  >
                    {{ $t("LB_MESSAGES") }}
                  </li>
                  <div class="divide-y divide-gray-200 w-full">
                    <li
                      v-if="unreadConversations?.length === 0"
                      class="text-black font-normal text-xs mb-3 tracking-wider"
                    >
                      {{ $t("LB_NO_MESSAGE_YET") }}
                    </li>
                    <template v-if="unreadConversations?.length > 0">
                      <li
                        v-for="conversation in unreadConversations"
                        :key="'single_message_' + conversation.id"
                        class="py-2 text-xs cursor-pointer hover:bg-gray-100 text-secondary"
                        style="width: 100%"
                        @click="gotoConversation(conversation)"
                      >
                        <div class="">
                          <div class="flex items-center gap-3">
                            <div class="relative shrink-0">
                              <img
                                class="rounded-full object-cover w-10 h-10"
                                :src="getUserImage(conversation.user)"
                                alt=""
                              >
                              <div
                                class="absolute inline-flex items-center justify-center w-6 h-6 text-[10px] font-bold text-white bg-beegup-blue border-2 border-white rounded-full -top-2 -right-2"
                              >
                                {{ conversation.unreadMessagesCount }}
                              </div>
                            </div>
                            <div class="flex flex-col" style="width:100%;">
                              <div class="flex items-start justify-between">
                                <div>{{ conversation.user.fullName }}<br role="presentation"></div>
                                <small class="text-[10px] mt-1 text-gray-600">
                                  {{ formatTime(conversation.lastMessage.createdAt) }}
                                </small>
                              </div>

                              <div v-if="conversation.type === 'TEXT'">
                                <p class="text-message text-xs text-gray-600">
                                  {{ truncateText(JSON.parse(conversation.lastMessage.content), 45) }}
                                </p>
                              </div>
                              <div v-if="conversation.type === 'AUDIO'">
                                <div class="text-message text-xs text-gray-600">
                                  Audio
                                </div>
                              </div>
                              <div v-if="conversation.type === 'CONTENT'">
                                <p class="text-message text-xs text-gray-600">
                                  Content
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </template>
                    <img
                      class="w-5 h-5 rounded-full"
                      src="/images/message_white.svg"
                      alt=""
                    >
                  </div>

                  <button
                    class="text-beegup-blue text-center font-bold text-xs mb-0 w-full flex justify-center tracking-wider"
                    @click.prevent="gotoChats"
                  >
                    {{ $t("LB_SEE_ALL_MESSAGES") }}
                  </button>
                </ul>
              </div>
            </div>

            <div class="profile">
              <button class="flex" type="button" @click="toggleProfile">
                <img
                  class="h-8 w-8 rounded-full"
                  :src="getUserImage(me)"
                  :alt="$t('LB_MENU')"
                  width="40"
                  height="40"
                >
              </button>
              <div
                v-if="isProfile"
                class="flex p-6 bg-white shadow-md text-black absolute md:top-14 top-32 divide-y right-0 mx-4 my-2 w-[18rem] z-10 rounded-lg scrollInMobile"
              >
                <ul
                  class="list-none flex flex-col items-start gap-4 w-full !pl-0"
                >
                  <div class="w-full space-y-2">
                    <div>
                      <li
                        class="flex items-center space-x-4 font-poppins py-2 text-xs font-semibold cursor-pointer hover:bg-gray-100 text-secondary"
                        @click="handleProfileClick()"
                      >
                        <p>
                          <NuxtLinkLocale
                            to="/my-profile"
                            class="flex items-center space-x-4 font-poppins py-2 text-xs font-semibold cursor-pointer"
                          >
                            <img src="/images/profile/person.svg" alt="" >
                            <p class="text-xs mb-0">{{ $t("LB_MY_PROFILE") }}</p>
                          </NuxtLinkLocale>
                        </p>
                      </li>
                      <li
                        class="flex items-center space-x-4 font-poppins py-2 text-xs font-semibold cursor-pointer hover:bg-gray-100 text-secondary"
                      >
                        <p>
                            <span
                              class="flex items-center space-x-4 font-poppins py-2 text-xs font-semibold cursor-pointer"
                              @click.prevent="tryToLogout"
                            >
                              <img src="/images/profile/logout.svg" alt="" >
                              <p class="text-xs mb-0">
                                {{ $t("LB_LOGOUT") }}
                              </p>
                            </span>
                        </p>
                      </li>
                    </div>
                    <hr
                      class="my-4 h-[1px] border-t-0 bg-beegup-light-grey opacity-100"
                    >
                    <div class="mt-2">
                      <li
                        class="flex items-center space-x-4 font-poppins py-2 text-xs font-semibold cursor-pointer hover:bg-gray-100 text-secondary"
                        @click="handleProfileClick()"
                      >
                        <p>
                          <NuxtLinkLocale
                            to="/faqs"
                            target="_blank"
                            class="flex items-center space-x-4 font-poppins py-2 text-xs font-semibold cursor-pointer"
                          >
                            <img src="/images/profile/faq.svg" alt="" >
                            <p class="text-xs mb-0">{{ $t("LB_FAQS") }}</p>
                          </NuxtLinkLocale>
                        </p>
                      </li>
                      <li
                        v-if="me.role !== 'GRADUATE'"
                        class="flex items-center space-x-4 font-poppins py-2 text-xs font-semibold cursor-pointer hover:bg-gray-100 text-secondary"
                        @click="handleProfileClick()"
                      >
                        <p>
                          <a
                            :href="
                                '/docs/' +
                                $i18n.locale +
                                '/parental-permission.pdf'
                              "
                            target="_blank"
                            class="flex items-center space-x-4 font-poppins py-2 text-xs font-semibold cursor-pointer"
                          >
                            <img src="/images/profile/parental.svg" alt="" >
                            <p class="text-xs mb-0">
                              {{ $t("LB_PARENTAL_PERMISSION") }}
                            </p>
                          </a>
                        </p>
                      </li>
                      <li
                        class="flex items-center space-x-4 font-poppins py-2 text-xs font-semibold cursor-pointer hover:bg-gray-100 text-secondary"
                        @click="handleProfileClick()"
                      >
                        <p>
                          <a
                            :href="
                                '/docs/' +
                                $i18n.locale +
                                '/terms-and-conditions.pdf'
                              "
                            target="_blank"
                            class="flex items-center space-x-4 font-poppins py-2 text-xs font-semibold cursor-pointer"
                          >
                            <img src="/images/profile/cgu.svg" alt="" >
                            <p class="text-xs mb-0">{{ $t("LB_TERMS_AND_CONDITIONS") }}</p>
                          </a>
                        </p>
                      </li>
                      <li
                        class="flex items-center space-x-4 font-poppins py-2 text-xs font-semibold cursor-pointer hover:bg-gray-100 text-secondary"
                        @click="handleProfileClick()"
                      >
                        <p>
                          <a
                            :href="
                                '/docs/' + $i18n.locale + '/legal-notice.pdf'
                              "
                            target="_blank"
                            class="flex items-center space-x-4 font-poppins py-2 text-xs font-semibold cursor-pointer"
                          >
                            <img src="/images/profile/cgu.svg" alt="" >
                            <p class="text-xs mb-0">
                              {{ $t("LB_LEGAL_NOTICE") }}
                            </p>
                          </a>
                        </p>
                      </li>
                      <li
                        class="flex items-center space-x-4 font-poppins py-2 text-xs font-semibold cursor-pointer hover:bg-gray-100 text-secondary"
                        @click="handleProfileClick()"
                      >
                        <p>
                          <a
                            :href="
                                '/docs/' + $i18n.locale + '/privacy-policy.pdf'
                              "
                            target="_blank"
                            class="flex items-center space-x-4 font-poppins py-2 text-xs font-semibold cursor-pointer"
                          >
                            <img src="/images/profile/cgu.svg" alt="" >
                            <p class="text-xs mb-0">
                              {{ $t("LB_PRIVACY_POLICY") }}
                            </p>
                          </a>
                        </p>
                      </li>
                      <li
                        class="flex items-center space-x-4 font-poppins py-2 text-xs font-semibold cursor-pointer hover:bg-gray-100 text-secondary"
                        @click="handleProfileClick()"
                      >
                        <p>
                          <a
                            :href="
                                '/docs/' + $i18n.locale + '/cookie-policy.pdf'
                              "
                            target="_blank"
                            class="flex items-center space-x-4 font-poppins py-2 text-xs font-semibold cursor-pointer"
                          >
                            <img src="/images/profile/cgu.svg" alt="" >
                            <p class="text-xs mb-0">
                              {{ $t("LB_COOKIES_POLICY") }}
                            </p>
                          </a>
                        </p>
                      </li>
                      <li
                        class="flex items-center space-x-4 font-poppins py-2 text-xs font-semibold cursor-pointer hover:bg-gray-100 text-secondary"
                        @click="handleProfileClick()"
                      >
                        <p>
                          <a
                            :href="
                                '/docs/' + $i18n.locale + '/charter.pdf'
                              "
                            target="_blank"
                            class="flex items-center space-x-4 font-poppins py-2 text-xs font-semibold cursor-pointer"
                          >
                            <img src="/images/profile/cgu.svg" alt="" >
                            <p class="text-xs mb-0">
                              {{ $t("LB_CHARTER") }}
                            </p>
                          </a>
                        </p>
                      </li>
                      <li
                        class="flex items-center space-x-4 font-poppins py-2 text-xs font-semibold cursor-pointer hover:bg-gray-100 text-secondary"
                        @click="handleProfileClick()"
                      >
                        <p>
                          <NuxtLinkLocale
                            to="/cookies-management"
                            class="flex items-center space-x-4 font-poppins py-2 text-xs font-semibold cursor-pointer"
                          >
                            <img src="/images/profile/cgu.svg" alt="" >
                            <p class="text-xs mb-0">
                              {{ $t("LB_COOKIES_MANAGEMENT") }}
                            </p>
                          </NuxtLinkLocale>
                        </p>
                      </li>
                    </div>
                    <hr
                      class="my-4 h-[1px] border-t-0 bg-beegup-light-grey opacity-100"
                    >
                    <div class="mt-2">
                      <li
                        v-if="me.role !== 'GRADUATE'"
                        class="flex items-center space-x-4 font-poppins py-2 text-xs font-semibold cursor-pointer hover:bg-gray-100 text-secondary"
                        @click="handleProfileClick()"
                      >
                        <p>
                          <a
                            :href="
                                '/docs/' + $i18n.locale + '/tutorial-' + me.role?.toLowerCase() + '.pdf'
                              "
                            target="_blank"
                            class="flex items-center space-x-4 font-poppins py-2 text-xs font-semibold cursor-pointer"
                          >
                            <img src="/images/profile/aide.svg" alt="" >
                            <p class="text-xs mb-0">
                              {{ $t("LB_TUTORIALS") }}
                            </p>
                          </a>
                        </p>
                      </li>
                      <li
                        class="flex items-center space-x-4 font-poppins py-2 text-xs font-semibold cursor-pointer hover:bg-gray-100 text-secondary"
                        @click="handleProfileClick()"
                      >
                        <p>
                          <NuxtLinkLocale
                            to="/contact"
                            class="flex items-center space-x-4 font-poppins py-2 text-xs font-semibold cursor-pointer"
                          >
                            <img src="/images/profile/contact.svg" alt="" >
                            <p class="text-xs mb-0">{{ $t("LB_CONTACT") }}</p>
                          </NuxtLinkLocale>
                        </p>
                      </li>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
    <audio id="incomingRing" ref="incomingRing" preload>
      <source src="/ringing.mp3" type="audio/mpeg" >
    </audio>
    <audio id="outgoingRing" ref="outgoingRing" preload>
      <source src="/outgoing_ring.ogg" type="audio/ogg" >
    </audio>
  </div>
</template>

<script setup>
import {useMainStore} from "~/store";

const localePath = useLocalePath()
const route = useRoute();

const me = ref({});
const keyword = ref("");
const displayedNotifications = ref([]);
const unreadConversations = ref([]);
const burgerMenuVisible = ref(false);
const showNotificationPanel = ref(false);
const showMessagePanel = ref(false);
const isProfile = ref(false);
const languageMenuVisible = ref(false);
const isBackdrop = ref(false);
const isTestActive = ref(false);

const { locale, locales, setLocale } = useI18n();

const availableLocales = computed(() => {
  return locales.value.filter((i) => i.code !== locale.value);
})

const loadData = async () => {
  await useNuxtApp().$chat.refreshMyConversations();
  await useNuxtApp().$chat.refreshNotifications();
};
const checkActiveTest = async () => {
  const activationCodes = me.value?.activationCodes;
  if (!activationCodes)
    return;
  for (const activationCode of activationCodes) {
    if (
      activationCode.tests.fr ||
      activationCode.tests.en ||
      activationCode.tests.es ||
      activationCode.tests.de
    ) {
      isTestActive.value = true;
    }
  }
};

keyword.value = route.query.keyword;
await useNuxtApp().$auth.getSession()
me.value = await useNuxtApp().$auth.user();
await loadData();
await checkActiveTest();
// useNuxtApp().$crisp.setUser(me.value);

const mainStore = useMainStore()
const {notifications, myConversations} = storeToRefs(mainStore)

watch(notifications, () => {
  displayedNotifications.value = mainStore.notifications;
})
watch(myConversations, () => {
  unreadConversations.value = mainStore.myConversations.filter(conversation => (conversation.unreadMessagesCount > 0));
})

const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + "...";
  }
  return text;
};
const toggleBackdrop = () => {
  if (
    languageMenuVisible.value ||
    showNotificationPanel.value ||
    burgerMenuVisible.value ||
    isProfile.value ||
    showMessagePanel.value
  ) {
    isBackdrop.value = false;
  }
  isBackdrop.value = !isBackdrop.value;
};
const toggleLanguageMenu = () => {
  languageMenuVisible.value = !languageMenuVisible.value;
  toggleBackdrop();
  showNotificationPanel.value = false;
  showMessagePanel.value = false;
  burgerMenuVisible.value = false;
  isProfile.value = false;
};
const handleProfileClick = () => {
  toggleBackdrop();
  hideBackdrop();
  // Add your logic for handling the profile click event here
};
const toggleProfile = () => {
  isProfile.value = !isProfile.value;
  toggleBackdrop();
  showNotificationPanel.value = false;
  showMessagePanel.value = false;
  languageMenuVisible.value = false;
  burgerMenuVisible.value = false;
};
const handleNotificationClick = async (notif) => {
  await useNuxtApp().$chat.setNotificationAsRead(notif);

  showNotificationPanel.value = false;
  isBackdrop.value = false;
  if (notif.category === "MISSED_CALL" || notif.category === "ADDITION_TO_COMMUNITY")
    await navigateTo(localePath("/profile/" + notif.referencedUser.uuid));
  else
    await navigateTo(localePath("/teacher/workspace"), {external: true});
  // external is to force reload if we are already on this page

};
const gotoChats = async () => {
  showMessagePanel.value = false;
  isBackdrop.value = false;
  return navigateTo(localePath("/my-messages/"));
};
const gotoConversation = async (conversation) => {
  const uuid = conversation.user.uuid;
  showMessagePanel.value = false;
  isBackdrop.value = false;
  await navigateTo(localePath("/my-messages/" + uuid + "?t=" + new Date().getTime()));
};
const toggleNotificationPanel = () => {
  showNotificationPanel.value = !showNotificationPanel.value;
  toggleBackdrop();
  showMessagePanel.value = false;
  languageMenuVisible.value = false;
  burgerMenuVisible.value = false;
  isProfile.value = false;
};
const toggleMenu = () => {
  burgerMenuVisible.value = !burgerMenuVisible.value;
  toggleBackdrop();
  isProfile.value = false;
  showNotificationPanel.value = false;
  showMessagePanel.value = false;
  languageMenuVisible.value = false;
};
const hideBackdrop = () => {
  showNotificationPanel.value = false;
  showMessagePanel.value = false;
  burgerMenuVisible.value = false;
  isProfile.value = false;
  languageMenuVisible.value = false;
  isBackdrop.value = false;
};
const toggleMessagePanel = () => {
  showMessagePanel.value = !showMessagePanel.value;
  toggleBackdrop();
  showNotificationPanel.value = false;
  languageMenuVisible.value = false;
  burgerMenuVisible.value = false;
  isProfile.value = false;
};
const tryToLogout = () => {
  useNuxtApp().$chat.close();
  useNuxtApp().$auth.signOut({ callbackUrl: localePath("/login") });
};
const doSearch = () => {
  return navigateTo({
    path: localePath("/search"),
    query: {
      keyword: keyword.value,
    },
  });
};
const formatTime = (dateStr) => {
  const date = new Date(dateStr);
  const options = {
    hour: "numeric", // Hour (e.g., "21")
    minute: "numeric", // Minute (e.g., "16")
    hour12: false, // 24-hour format (e.g., "21:16")
  };
  const formatter = new Intl.DateTimeFormat(locale, options);
  const formattedTime = formatter.format(date);
  return formattedTime;
};
const gotoWorkspace = () => {
  if (me.value.role === "TEACHER") {
    navigateTo(localePath("/teacher/workspace"));
  }
  if (me.value.role === "STUDENT") {
    navigateTo(localePath("/student/workspace"));
  }
  burgerMenuVisible.value = !burgerMenuVisible.value;
  toggleBackdrop();
};
const gotoTests = () => {
  navigateTo(localePath("/tests"));
  burgerMenuVisible.value = !burgerMenuVisible.value;
  isBackdrop.value = false;
};

const chooseLanguage = (locale) => {
  languageMenuVisible.value = false;
  isBackdrop.value = false;
  setLocale(locale.code);
}
const gotoHome = () => {
  navigateTo(localePath("/"));
  burgerMenuVisible.value = !burgerMenuVisible.value;
  isBackdrop.value = false;
};
const gotoMyCommunity = () => {
  navigateTo(localePath("/my-community"));
  burgerMenuVisible.value = !burgerMenuVisible.value;
  isBackdrop.value = false;
};
</script>

<style scoped>
* {
  color: #241332;
}

header {
  display: flex;
  flex-wrap: wrap;
  z-index: 50;
  width: 100%;
  position: fixed;
  /* inset: 0; */
  top: 0;
  right: 0;
  left: 0;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  background-color: #ffffff;
  --bg-opacity: 0.3;
  backdrop-filter: blur(16px);
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Adjust the opacity as needed */
  z-index: 10;
}

.orange-gradient {
  color: white;
  background: #ff8415;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #ff8415, #ff8415);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #ff8415, #ff8415);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

p {
  margin-bottom: 0px !important;
}

.scrollInMobile {
  height: 32rem;
  overflow-y: auto;
}

.header_logo {
  max-width: 122px;
}
</style>
